<script>
  import BaseTagGroup from '@/components/BaseTagGroup'
  import ProductPrice from '@/components/products/ProductPrice'

  /** Basic information about a product. */

  export default {
    name: 'ProductInfo',

    components: {
      BaseTagGroup,
      ProductPrice,
    },

    props: {
      productTitle: {
        type: String,
        required: true,
      },
      brand: {
        type: Object,
        required: true,
      },
      designer: {
        type: Object,
        required: false,
        default: () => ({
          id: 0,
          title: '',
          url: '',
          image: {
            urls: [],
            alt: '',
          },
          description: '',
        }),
      },
      /** Some products are part of a collection. Passed to Description */
      collection: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      /** Passed to BaseTagGroup */
      tags: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** Passed to ProductPrice */
      isAvailable: {
        type: Boolean,
        required: true,
        default: false,
      },
      /** Passed to ProductPrice */
      selectedVariantId: {
        type: [String, Number],
        default: undefined,
      },
      /** Passed to ProductPrice */
      variants: {
        type: Array,
        required: true,
      },
    },
  }
</script>

<template>
  <header>
    <div>
      <a
        :href="brand.url"
        class="inline-block text-gray-600 leading-none hover:text-black mb-2.5"
        >{{ brand.title }}</a
      >
    </div>

    <h1>{{ productTitle }}</h1>

    <div v-if="tags.length" class="-mt-5">
      <BaseTagGroup :tags="tags" />
    </div>

    <div class="mt-6">
      <ProductPrice
        :product-title="productTitle"
        :selected-variant-id="selectedVariantId"
        :variants="variants"
        :is-available="isAvailable"
      />
    </div>

    <!--<div v-if="designer.id" class="mt-2 md:mt-4 text-gray-600">
      <a
        :href="designer.url"
        class="inline-block border-b border-dotted border-transparent hover:border-gray-600"
        >{{ designer.title }}</a
      >
    </div>-->
  </header>
</template>

<style scoped lang="postcss">
  h1 {
    margin-bottom: 30px;
    font-family: 'Unica77', sans-serif;
    font-size: 18px;
    line-height:24px;
    text-transform: uppercase;
    font-weight: 400 !important;
  }
</style>
