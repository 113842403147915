<script>
  import VLazyImage from 'v-lazy-image'

  /** Partial of a slide for the BuilderHeroSlider. There's some funny business with flexbox
   * between this and the parent element.
   * Makes use of lazy image loading
   * via the VLazyImage component.
   * See: https://github.com/alexjoverm/v-lazy-image */

  export default {
    name: 'BuilderHeroSliderSlide',

    components: {
      VLazyImage,
    },

    props: {
      /** Max height of the parent slider */
      slideHeight: {
        type: String,
        required: false,
        default: 'height: 100vh',
      },
      allSlides: {
        type: Number,
        default: 0,
      },
      index: {
        type: Number,
        default: 0,
      },
      /** Small mobile image object */
      smallImage: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      parentRef: {
        type: Object,
        default: () => ({}),
      },
      /** Large mobile image object */
      largeImage: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** Slide heading */
      heading: {
        type: String,
        required: false,
        default: null,
      },
      /** Slide summary */
      summary: {
        type: String,
        required: false,
        default: null,
      },
      /** Image overlay color */
      ctaColor: {
        type: String,
        required: false,
        default: 'rgba(0, 0, 0, .3)',
      },
      blocks: {
        type: Array,
        default: () => [],
      },
      /** The slide primary CTA button object (link) */
      button: {
        type: Object,
        required: false,
        default: () => ({
          url: '',
          target: '',
          text: '',
        }),
      },
      /** The slide secondary CTA object (link) */
      link: {
        type: Object,
        required: false,
        default: () => ({
          url: '',
          target: '',
          text: '',
        }),
      },
    },

    computed: {
      overlayStyle: function () {
        /** Applies an overlay to the background for accessibility reasons */
        return `background-color: ${this.ctaColor}`
      },
      /** Create the base64 SVG loading image */
      srcPlaceholder: function () {
        return 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTM2MCA2NzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiIHN0eWxlPSdmaWxsOiB1cmwoIiNmaWxsIik7JyAvPgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPgogICAgICA8cmVjdCB4PSIwIiB5PSIwIiByeD0iMCIgcnk9IjAiIHdpZHRoPSIxMzYwIiBoZWlnaHQ9IjY3MCIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImZpbGwiPgogICAgICA8c3RvcAogICAgICAgIG9mZnNldD0iMC41OTk5NjQiCiAgICAgICAgc3RvcC1jb2xvcj0iI2YzZjNmMyIKICAgICAgICBzdG9wLW9wYWNpdHk9IjEiCiAgICAgID4KICAgICAgICA8YW5pbWF0ZQogICAgICAgICAgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IgogICAgICAgICAgdmFsdWVzPSItMjsgLTI7IDEiCiAgICAgICAgICBrZXlUaW1lcz0iMDsgMC4yNTsgMSIKICAgICAgICAgIGR1cj0iMnMiCiAgICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgICA+PC9hbmltYXRlPgogICAgICA8L3N0b3A+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIxLjU5OTk2IgogICAgICAgIHN0b3AtY29sb3I9IiNlY2ViZWIiCiAgICAgICAgc3RvcC1vcGFjaXR5PSIxIgogICAgICA+CiAgICAgICAgPGFuaW1hdGUKICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIKICAgICAgICAgIHZhbHVlcz0iLTE7IC0xOyAyIgogICAgICAgICAga2V5VGltZXM9IjA7IDAuMjU7IDEiCiAgICAgICAgICBkdXI9IjJzIgogICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiCiAgICAgICAgPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcAogICAgICAgIG9mZnNldD0iMi41OTk5NiIKICAgICAgICBzdG9wLWNvbG9yPSIjZjNmM2YzIgogICAgICAgIHN0b3Atb3BhY2l0eT0iMSIKICAgICAgPgogICAgICAgIDxhbmltYXRlCiAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJvZmZzZXQiCiAgICAgICAgICB2YWx1ZXM9IjA7IDA7IDMiCiAgICAgICAgICBrZXlUaW1lcz0iMDsgMC4yNTsgMSIKICAgICAgICAgIGR1cj0iMnMiCiAgICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgICA+PC9hbmltYXRlPgogICAgICA8L3N0b3A+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+Cg=='
      },
    },

    methods: {
      /** Go to previous slide */
      showPrev() {
        this.parentRef.slider.prev()
        this.$refs.prev.blur()
      },
      /** Go to next slide */
      showNext() {
        this.parentRef.slider.next()
        this.$refs.next.blur()
      },
    },
  }
</script>

<template>
  <article class="relative pt-16 lg:pt-0 SliderSlideRow" :style="slideHeight">
    <div class="mx-auto lg:max-w-screen-2xl py-24 lg:py-0 px-4 md:px-6 h-full">
      <div class="w-full h-full relative z-10">
        <!-- The text section -->
        <div
          class="w-full flex text-center text-white relative heroSliderTextSection md:-mb-0 lg:justify-center lg:h-full flex-col"
        >
          <h3 class="block font-bold font-cormorant mb-2 md:mb-5">
            {{ heading }}
          </h3>

          <!-- The lower part -->
          <div class="flex flex-wrap items-center flex-col w-full">
            <div
              v-if="summary"
              class="px-11 text-xl leading-7 font-unica77 font-normal tracking-02 w-full md:w-2/4 mb-5 heroSliderContent lg:mb-10"
              v-html="summary"
            ></div>
            <div
              v-if="button || link"
              class="flex flex-col justify-center items-start space-y-4 md:space-y-0 md:flex-row md:items-center | max-w-prose | text-sm uppercase font-semibold text-center"
            >
              <a
                :href="button.url"
                class="w-full text-center block font-unica77 text-xl font-bold | md:inline-block | bg-transparent text-white 0 uppercase tracking-150 underline hover:no-underline underline-offset-8"
              >
                {{ button.text }}
              </a>
              <a
                :href="link.url"
                class="ml-0 md:ml-7 | hover:underline focus:underline tracking-wide2x text-black"
              >
                {{ link.text }}
              </a>
            </div>
          </div>
        </div>
        <!--<div 
          class="hidden lg:flex flex-row items-center leading-none pt-12 lg:pt-20 heroSliderTextSection__buttons"
          :class="`${ blocks.length !== 0 ? 'absolute right-0 bottom-0 lg:bottom-9' : '' }`"
        >
          <button
            ref="prev"
            name="Previous Slide"
            class="hidden md:block w-8 h-3 slider--arrow--prev"
            @click="showPrev"
          >
            <span class="sr-only">Previous Slide</span>
            <IconSliderArrow class="block" />
          </button>
          <span class="block ml-8 mr-8 mt-1 md:text-center text-xl">
            {{ index + 1 }}<em class="text-gray-600 not-italic">/{{ allSlides }}</em>
          </span>
          <button
            ref="next"
            name="Next Slide"
            class="hidden md:block | w-8 h-3 slider--arrow--next"
            @click="showNext"
          >
            <span class="sr-only">Next Slide</span>
            <IconSliderArrow class="block" />
          </button>
        </div>-->
      </div>
    </div>
    <div class="w-full h-full absolute left-0 top-0 z-0 heroSliderFullWidthImage">
      <div v-if="smallImage && smallImage.urls.length" class="md:hidden h-full">
        <VLazyImage
          :srcset="smallImage.urls[0]"
          :src="srcPlaceholder"
          sizes="375px"
          :alt="largeImage.alt"
          class="lg:absolute inset-0 w-full h-96 lg:h-full object-cover"
        ></VLazyImage>
      </div>
      <div class="hidden md:block">
        <VLazyImage
          :srcset="largeImage.urls[0]"
          :src="srcPlaceholder"
          sizes="768px"
          :alt="largeImage.alt"
          class="lg:absolute inset-0 w-full h-96 lg:h-full object-cover pointer-events-none"
        ></VLazyImage>
      </div>
      <div
        v-if="largeImage.attribution"
        class="absolute bottom-0 right-0 py-1 text-xs inline px-4 rounded-tl z-10"
        :style="{ color: largeImage.attributionColor }"
        v-html="largeImage.attribution"
      ></div>
    </div>
  </article>
</template>

<style scoped lang="postcss">
  >>> .heroSliderContent {
    @media (max-height: 800px) {
      margin-bottom: 30px;
    }
  }

  .heroSliderFullWidthImage {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .slider--arrow--next {
    transform: rotate(180deg);
  }

  .heroSliderTextSection {
    padding-top: 0;

    h3 {
      font-size: 60px;
      line-height: 72px;
    }
    @media (max-height: 840px) {
      &__buttons {
        padding-top: 35px;
      }
    }
    @media (max-height: 700px) {
      h3 {
        margin-bottom: 10px;
      }

      &__buttons {
        padding-top: 20px;
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 2rem;

      &__buttons {
        display: none;
      }

      h3 {
        width: 100%;
      }
    }
    @media (max-width: 1023px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .SliderSlideRow {
    @media (max-width: 1024px) {
      flex-direction: column;
      padding-top: 0;

      div {
        width: 100%;

        img {
          position: static;
          height: 100%;
        }
      }
    }
  }

  .heroSliderImage {
    height: 500px;
    margin-bottom: 23px;
    @media (max-height: 900px) {
      height: 350px;
    }
    @media (max-height: 780px) {
      height: 300px;
    }
    @media (max-width: 1280px) {
      height: 320px;
    }
  }

  .SliderSlideRow .heroSliderImageWrapper {
    @media (max-height: 770px) {
      margin-top: -20px;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      padding-top: 0;

      &__item {
        width: 100%;

        img {
          height: 100%;
        }

        &:last-child {
          padding-bottom: 1rem;
          margin-top: 2rem;
        }
      }
    }
    @media (max-width: 1023px) {
      padding-top: 2rem;
    }
  }
  @media (max-width: 1024px) {
    article {
      height: auto !important;
    }
  }
</style>
