<script>
  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')
  /** Shows a variant's MSRP and any discounted prices */
  export default {
    name: 'ProductPrice',

    props: {
      /** Pass the variants through from the view template */
      variants: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** This is the selected variant (purchasable) */
      selectedVariantId: {
        type: [String, Number],
        default: undefined,
      },
      /** Whether the product is available for purchase */
      isAvailable: {
        type: Boolean,
        required: true,
      },
      productTitle: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        defaultVariant: {},
        currentVariant: {},
      }
    },

    computed: {
      ...mapGlobalGetters(['currentUser', 'loggedIn', 'isTradeAccount']),
      /** The variant we're getting prices from. It's the current variant as long as it has a price;
       * the default variant if not. */
      pricedVariant: function () {
        // if(this.hasPrice) {
        //   this.$store.dispatch('products/setSelectedSale', this.currentVariant.discountPrices[0].label)
        // }
        return this.hasPrice ? this.currentVariant : this.defaultVariant
      },

      /** Does the current variant have a price? */
      hasPrice: function () {
        return this.currentVariant.basePrice > 0
      },

      /** Return true when the product is not available for purchase but there is a price,
       * or when there's a price for the default variant, but not the current variant. */
      useStartingPrice: function () {
        return (
          (!this.isAvailable && this.currentVariant.basePrice > 0) ||
          (this.currentVariant.basePrice === 0 && this.defaultVariant.basePrice > 0)
        )
      },

      /** Label text to display with the price. */
      priceLabel: function () {
        if (this.useStartingPrice) {
          return 'Starts from'
        } else if (this.hasPrice) {
          return 'Price'
        } else {
          return 'Price on request'
        }
      },
    },

    watch: {
      /** Just in case variants change? */
      variants: {
        immediate: true,
        handler(newVal) {
          this.defaultVariant = this.getDefaultVariant()
        },
      },
      /** As the product builder changes the selected variant, update it. */
      selectedVariantId: {
        immediate: true,
        handler(newVal) {
          this.setVariant(newVal)
        },
      },
    },

    mounted() {
      this.defaultVariant = this.getDefaultVariant()
      this.setVariant(this.selectedVariantId)
    },

    methods: {
      getDefaultVariant() {
        const defaultVariant = this.variants.filter((variant) => variant.isDefault)
        if (defaultVariant.length) {
          return defaultVariant[0]
        }
      },

      setVariant(id) {
        const currentVariant = this.variants.filter((variant) => variant.id === id)
        if (currentVariant.length) {
          this.currentVariant = currentVariant[0]
        }
      },

      hasDiscount: function (variant) {
        return variant.discountPrices.length
      },

      labelClass: function (variant, index) {
        if (this.hasDiscount(variant) > index + 1) {
          return 'text-gray-600'
        } else {
          return 'text-black'
        }
      },

      priceClass: function (variant, index) {
        if (this.hasDiscount(variant) > index + 1) {
          return 'text-gray-600 line-through'
        } else {
          return 'text-black font-bold tracking-wide text-xl'
        }
      },

      // new submit form request
      /** TODO: Make this a plugin or something we can use across templates. */
      formatCurrency(amount, decimalCount = 2, decimal = '.', thousands = ',', symbol = '$') {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount
        const negativeSign = amount < 0 ? '-' : ''
        const i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString()
        const j = i.length > 3 ? i.length % 3 : 0
        return (
          negativeSign +
          symbol +
          (j ? i.substr(0, j) + thousands : '') +
          i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : '')
        )
      },
    },
  }
</script>

<template>
  <dl class="mt-6">
    <dl class="flex flex-no-wrap flex-col" v-if="isTradeAccount">
      <dl v-if="hasPrice" class="mb-6">
        <span
          v-if="hasPrice && !isAvailable"
          class="tracking-wide text-sm block mb-2 uppercase font-semibold"
          >The starting price for the {{ productTitle }} is</span
        >
        <!-- Show the MSRP-->
        <div class="mr-3">
          <dt
            id="MSRP"
            class="text-black font-bold tracking-wide text-xl"
            :class="labelClass(pricedVariant, -1)"
          >
            <span v-if="pricedVariant.discountPrices.length">MSRP</span>
          </dt>
          <dd :class="priceClass(pricedVariant, -1)" aria-labelledby="MSRP">
            {{ formatCurrency(pricedVariant.basePrice, 0) }}
          </dd>
        </div>

        <!-- Show all discounted prices -->
        <div v-for="(price, index) in pricedVariant.discountPrices" :key="price.value" class="mr-3">
          <dt
            :id="price.label"
            class="text-black font-bold tracking-wide text-xl"
            :class="labelClass(pricedVariant, index)"
            >{{ price.label }}
          </dt>
          <dd :class="priceClass(pricedVariant, index)" :aria-labelledby="price.label">
            {{ formatCurrency(price.value, 0) }}
          </dd>
        </div>
      </dl>
      <span v-else class="uppercase block tracking-wide font-normal text-gray-600 mb-8"
        >Price on request</span
      >
    </dl>
    <dl class="flex flex-no-wrap flex-col" v-else>
      <!-- Show all discounted prices -->
      <div v-if="hasPrice && isAvailable" class="mb-6">
        <!-- Show the MSRP-->
        <div class="mr-3">
          <dt
            id="MSRP"
            class="text-black font-bold tracking-wide text-xl"
            :class="labelClass(pricedVariant, -1)"
          >
            <span v-if="pricedVariant.discountPrices.length">MSRP</span>
          </dt>
          <dd :class="priceClass(pricedVariant, -1)" aria-labelledby="MSRP">
            {{ formatCurrency(pricedVariant.basePrice, 0) }}
          </dd>
        </div>
        <div v-for="(price, index) in pricedVariant.discountPrices" :key="price.value" class="mr-3">
          <dt
            :id="price.label"
            class="text-black font-bold tracking-wide text-xl"
            :class="labelClass(pricedVariant, index)"
            >{{ price.label }}
          </dt>
          <dd :class="priceClass(pricedVariant, index)" :aria-labelledby="price.label">
            {{ formatCurrency(price.value, 0) }}
          </dd>
        </div>
      </div>
      <span v-else class="uppercase block tracking-wide font-normal text-gray-600 mb-8 text-sm"
        >Price on request</span
      >
    </dl>
    <p
      v-if="isTradeAccount && !isAvailable && hasPrice"
      class="block font-medium italic w-full text-italic text-xs leading-4 mb-8"
    >
      Please be aware that many of our products offer various sizing and material options, and these
      choices may impact the final price. Shipping and crating (if necessary) costs not included.
      Prices are subject to change.
    </p>
  </dl>
</template>
